import { OnboardingStep } from './data/onboardingSteps.ts'
import { ProfileSections } from './data/profile.ts'
export const isWebDomain = Boolean('')
export const isNoDemoDomain = import.meta.env.VITE_APP_DOMAIN == 'web'

export const urls = {
  index: '/',
  learningProfile(rest?: string) {
    return rest ? `/learning-profile/${rest}` : '/learning-profile'
  },
  progress: '/progress',
  contacts: '/contacts',
  lesson(tag: string, rest?: string) {
    const prefix = `/lesson/${tag}`
    return rest ? `${prefix}/${rest}` : prefix
  },
  vocabLesson(tag: string, rest?: string) {
    const prefix = `/vocabulary/${tag}`
    return rest ? `${prefix}/${rest}` : prefix
  },
  login: '/login',
  registration: '/registration',
  payment(
    status: 'success' | 'error' | ':status',
    plan: '1m' | '3m' | '12m' | ':plan?',
  ) {
    return `/payment/${status}/${plan}`
  },
  onboarding(step?: OnboardingStep | ':step?') {
    return step ? `/onboarding/${step}` : '/onboarding'
  },
  subscriptions: '/subscriptions',
  subscriptions_kz: '/subscriptions_kz',
  confirm: '/confirm',
  chooseyourlevel: '/choose-your-level',
  demo(infoBlock?: string) {
    return infoBlock ? `/demo/${infoBlock}` : '/demo'
  },
  profile(section?: ProfileSections) {
    return section ? `/profile/${section}` : '/profile'
  },

  program: '/',
  practice: '/practice',
}
