import { useNavigate } from 'react-router-dom'
import { ChatStore } from '../chats/chatStore'
import { VocabLessonStore } from '../vocabLessons/vocabLessonStore'
import { useSnapshot } from 'valtio'
import { urls } from '../../shared/urls'
import { AnimatePresence } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import {
  IMetricCard,
  lessonMetricCards,
} from '../../shared/data/progressMetrics'
import { MetricKeysEnum, getValueByIndex } from '../../shared/lib/utils'
import MetricCard from '../../shared/ui/metricCard/metricCard'
import { BottomButtons } from '../../shared/ui/bottomButtons'

export const LessonStatistics = ({
  store,
}: {
  store: ChatStore | VocabLessonStore
}) => {
  const navigate = useNavigate()
  // const store = useStores().chatStore
  const state = useSnapshot(store.state)

  const isDemo = state.isDemo
  const metrics = state.statistics.metrics
  const isVocabLesson = state.lessonType === 'vocabulary'

  const handleContinue = () => {
    navigate(
      isDemo
        ? urls.subscriptions
        : isVocabLesson
        ? urls.practice
        : urls.program,
    )
    store.resetLessonStat()
  }

  return (
    <div
      className="fixed left-0 top-0 z-30 h-[100dvh] w-full bg-white"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <AnimatePresence>
        <div className="mt-100 h-full msm:mt-[25%]">
          <p className="mx-auto max-w-[50%] text-center text-44 font-extrabold text-black">
            <FormattedMessage
              id="Great job"
              values={{ name: state.statistics.username }}
            />
          </p>
          <p className="mx-32 mt-16 text-center text-14 text-gray4">
            <FormattedMessage id="Tips for you to practice" />
          </p>
          <div className="mx-16 mt-32 flex h-fit min-h-136 gap-10">
            {lessonMetricCards.map((el: IMetricCard) => {
              const key = el.metricKey
              const getMetricValue = (): number | '?' => {
                if (metrics === null) return '?'

                if (
                  key === MetricKeysEnum.UniqueWordsChat ||
                  key === MetricKeysEnum.ErrorsChat
                ) {
                  return (
                    getValueByIndex(metrics[`${key}${state.programTag}`], 0)
                      ?.value ?? '?'
                  )
                }

                return getValueByIndex(metrics[key], 0)?.value ?? '?'
              }

              return (
                <MetricCard key={key} value={getMetricValue()} element={el} />
              )
            })}
          </div>
        </div>
      </AnimatePresence>
      <BottomButtons
        firstButtonProps={{
          handler: () => {
            void store.newChat(state.programTag, true, state.lessonType)
            store.resetLessonStat()
          },
          textId: 'Repeat the lesson',
          bg: 'transparent',
          visible: !state.isDemo,
        }}
        secondButtonProps={{
          handler: () => {
            handleContinue()
          },
          textId: 'Continue',
          bg: 'blue-gradient',
          visible: true,
        }}
      />
    </div>
  )
}
