import { useAppModule } from '../appContext.ts'
import { Dialog } from '../../shared/ui/dialog/dialog.tsx'
import {
  ImageHideBeforeLoad,
  InstructionImage,
} from '../../shared/ui/images/images.tsx'
import { Button, LinkButton } from '../../shared/ui/button/button.tsx'
import { urls } from '../../shared/urls.ts'
import React from 'react'
import { classed } from '@tw-classed/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSnapshot } from 'valtio/react'
import { ChatTextInput, useStores } from './chatView.tsx'
import { convertToId } from '../../shared/lib/stringUtils.ts'
import { cn, getLast } from '../../shared/lib/utils.ts'
import { ImageLoader } from '../../shared/ui/spinner.tsx'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { EdmanBlock } from '../onboarding/OnboardingView.tsx'
import { EdmanAvatar } from '../programs/programView.tsx'

const DemoButtonCotainer = classed.button(
  'flex w-full cursor-pointer items-center overflow-hidden rounded-8 border border-purple bg-white/80 px-24 py-12 text-16 font-bold leading-tight',
  {
    variants: {
      topic: {
        true: 'max-w-300 flex-col justify-between gap-4',
        false: 'h-80 max-w-400 gap-12 text-left',
      },
    },
  },
)

function DemoEdmanBlock(props: {
  text: string
  dark?: boolean
  className?: string
}) {
  return (
    <div className={cn('flex items-end gap-8', props.className)}>
      <EdmanAvatar className="size-68" />
      <EdmanBlock
        fast={props.dark}
        text={props.text}
        left={true}
        hideMute={true}
      />
    </div>
  )
}

// const DemoInfoBlockContainer = classed.div(
//   'flex w-full flex-1 overflow-y-auto bg-light pb-[10%]',
// )

// export function DemoInfoBlock() {
//   const store = useStores().chatStore
//   const state = useSnapshot(store.state)

//   if (!state.showChatBlock && state.loading) {
//     return (
//       <DemoInfoBlockContainer>
//         <Spinner size="large" className="m-auto" />
//       </DemoInfoBlockContainer>
//     )
//   }

//   if (!state.infoBlock) {
//     return null
//   }
//   return <InfoBlockView infoBlock={state.infoBlock} />
// }

// export function InfoBlockView(props: {
//   infoBlock: InfoBlockType
// }): React.ReactNode | null {
//   const navigate = useNavigate()
//   const blocks = {
//     demo_level: <DemoLevel />,
//     demo_topic: <DemoTopic />,
//     demo_username: <DemoName />,
//     demo_subtopic: <DemoSubtopic />,
//     demo_complete: null,
//   }
//  // const block = blocks[props.infoBlock]
//   useEffect(() => {
//     const infoBlock =
//       props.infoBlock == 'demo_level' || !block ? undefined : props.infoBlock
//     navigate(urls.demo(infoBlock), { replace: true })
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [props.infoBlock])
//   return (
//     <DemoInfoBlockContainer>
//       <div className="m-auto px-8 pt-8">{block}</div>
//     </DemoInfoBlockContainer>
//   )
// }

export function DemoTopic() {
  const store = useStores().chatStore
  const intl = useIntl()

  function block(level: number) {
    const names = ['Travel', 'World cuisine', 'Work', 'Leisure'] as const
    const name = names[level - 1]
    return (
      <DemoButtonCotainer
        onClick={() => {
          void store.send(name)
        }}
        id={'send_demo_topic_' + convertToId(name)}
        className="min-h-104"
        topic={true}
        key={level}
      >
        <FormattedMessage id={name} />
        <ImageHideBeforeLoad src={`/images/demo/topic-${level}.svg`} />
      </DemoButtonCotainer>
    )
  }
  const items = [1, 2, 3, 4]
  return (
    <div className="flex flex-col items-center gap-16 px-24 text-24 font-bold">
      <DemoEdmanBlock text={intl.formatMessage({ id: 'demo.typeTopic' })} />
      <div className="grid w-full grid-cols-2 place-items-center gap-16">
        {items.map((level) => block(level))}
      </div>
    </div>
  )
}

export function DemoSubtopic() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const intl = useIntl()
  const [imageVisible, setImageVisible] = React.useState(false)
  const loadedCount = React.useRef(0)
  const message = getLast(state.messages)
  if (!message) {
    return null
  }
  const pictureUrls = message.picture_urls ?? []
  function block(data: { url: string; title: string }) {
    const img = (
      <ImageHideBeforeLoad
        className="animate-in fade-in"
        width={896}
        height={512}
        id={'send_demo_subtopic_' + convertToId(data.title)}
        onLoaded={() => {
          loadedCount.current += 1
          if (loadedCount.current >= pictureUrls.length) {
            setTimeout(() => {
              setImageVisible(true)
            }, 2000)
          }
        }}
        src={data.url}
      />
    )
    return (
      <div key={data.title}>
        <button
          onClick={() => {
            void store.send(data.title)
          }}
          className={cn(
            'grid aspect-video overflow-hidden rounded-8',
            !imageVisible && 'border border-purple bg-white',
          )}
        >
          {imageVisible ? (
            img
          ) : (
            <>
              <div className={cn('col-start-1 row-start-1 opacity-0')}>
                {img}
              </div>
              <ImageLoader
                className={cn(
                  'col-start-1 row-start-1 self-center justify-self-center transition-opacity',
                )}
              />
            </>
          )}
        </button>
        <div className="mt-2 text-center font-bold">{data.title}</div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center gap-16">
      <DemoEdmanBlock text={intl.formatMessage({ id: 'demo.typeSubtopic' })} />
      <div className="grid w-full max-w-300 grid-cols-1 gap-16 sm:max-w-none sm:grid-cols-3">
        {pictureUrls.map((level) => block(level))}
      </div>
    </div>
  )
}

export function DemoComplete() {
  const authStore = useAppModule().authStore
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const text = getLast(state.messages)?.message_text
  if (!text) {
    return null
  }
  const buttons = (
    <div className="mt-32 grid gap-24 sm:grid-cols-2 ">
      <Button
        bg="transparent"
        size="extralarge"
        id="demo_try_again"
        onClick={() => {
          authStore.trackEvent('click_again')
          setTimeout(() => {
            window.location.reload()
          }, 100)
        }}
        className="whitespace-nowrap border-2"
        rounded="full"
      >
        <FormattedMessage id="Try again" />
      </Button>
      <LinkButton
        size="extralarge"
        bg="violet"
        id="demo_buy_subscription"
        to={urls.subscriptions}
        onClick={() => {
          authStore.trackEvent('click_selection')
        }}
        rounded="full"
        className="relative whitespace-nowrap text-white"
      >
        <FormattedMessage id="Get subscription" />
        <img className="absolute -right-4 -top-12" src="/images/stars.svg" />
      </LinkButton>
    </div>
  )
  const items = [
    'demo.Personalized Program',
    'demo.Vocabulary Lessons',
    'demo.General Conversation Lessons',
    'demo.Role Play',
    'demo.Grammar Lessons',
    'demo.Exercises',
  ] as const
  return (
    <div className="flex flex-col items-center bg-violetd pb-16 text-white">
      <ImageHideBeforeLoad
        height={213}
        width={300}
        className="mb-48 mt-32 w-300"
        src="/images/demo-complete.svg"
      />

      <div className="px-16 sm:max-w-min">
        <DemoEdmanBlock dark={true} className="mb-40 sm:-mx-100" text={text} />
        <div className="flex items-center gap-4 text-12 font-medium">
          <Icon iconName="key" /> <FormattedMessage id="Unlock Edman" />
        </div>
        <div className="mb-16 text-24 font-bold">
          <FormattedMessage id="Get full access" />
        </div>
        <div className="grid gap-x-40 gap-y-16 sm:grid-cols-[max-content_max-content]">
          {items.map((x, i) => (
            <div className="flex max-w-fit items-center gap-8" key={i}>
              <Icon className="shrink-0 text-green" iconName="check" />{' '}
              <FormattedMessage id={x} />
            </div>
          ))}
        </div>
      </div>
      {buttons}
    </div>
  )
}

export function DemoName() {
  const intl = useIntl()
  return (
    <div className="flex flex-col items-center gap-16 px-24 text-20 font-semibold">
      <DemoEdmanBlock text={intl.formatMessage({ id: 'demo.username' })} />
      <ChatTextInput />
    </div>
  )
}

export function DemoLevel() {
  const store = useStores().chatStore

  function block(level: 'Beginner' | 'Intermediate' | 'Advanced') {
    const names = {
      Beginner: ['A2', 'onboarding.a2'],
      Intermediate: ['B1', 'onboarding.b1'],
      Advanced: ['B2', 'onboarding.b2'],
    } as const
    const name = names[level]
    return (
      <DemoButtonCotainer
        onClick={() => {
          void store.send(level)
          store.setLanguageLevel(name[0])
        }}
        id={'send_demo_level_' + convertToId(level)}
        topic={false}
        key={level}
      >
        <ImageHideBeforeLoad
          className="size-56 shrink-0"
          src={`/images/level-${name[0]}.svg`}
        />
        <FormattedMessage id={name[1]} />
      </DemoButtonCotainer>
    )
  }

  const levels = ['Beginner', 'Intermediate', 'Advanced'] as const
  const intl = useIntl()
  return (
    <div className="flex flex-col items-center gap-16 px-12 text-24 font-bold">
      <DemoEdmanBlock text={intl.formatMessage({ id: 'demo.typeLevel' })} />
      <div className="flex flex-col items-center gap-16">
        {levels.map((level) => block(level))}
      </div>
    </div>
  )
}

export const VocabularyContainer = classed.div(
  'w-full shrink-0 flex-col gap-8 overflow-y-auto rounded-6 p-16 sm:bg-white sm:shadow-card',
)
export const DemoChoosePlan = () => {
  return (
    <VocabularyContainer>
      <div className="text-16 font-semibold">
        <FormattedMessage id="demoDescription" />
      </div>
      <LinkButton
        bg="violet"
        id="demo_choose_plan"
        rounded="full"
        size="large"
        className="mx-auto mt-16 max-w-fit"
        to={urls.subscriptions}
      >
        <FormattedMessage id="Get subscription" />
      </LinkButton>
    </VocabularyContainer>
  )
}
export const DemoBuySubscriptionDialog = () => {
  const authStore = useAppModule().authStore
  return (
    <Dialog.Root open={true}>
      <Dialog.Content local className="max-w-[min(95%,400px)]" closable={false}>
        <div className="flex flex-col gap-16">
          <div className="flex items-center gap-16 text-24 font-bold">
            <InstructionImage />
            <FormattedMessage id="Lesson is completed" />
          </div>
          <p>
            <FormattedMessage id="demoCompleted" />
          </p>
          <div className="flex flex-col gap-12 sm:flex-row">
            <Button
              bg="white"
              id="demo_try_again"
              onClick={() => {
                authStore.trackEvent('click_again')
                setTimeout(() => {
                  window.location.reload()
                }, 100)
              }}
              className="sm:flex-1"
              rounded="full"
            >
              <FormattedMessage id="Try again" />
            </Button>
            <LinkButton
              bg="white"
              id="demo_buy_subscription"
              to={urls.subscriptions}
              onClick={() => {
                authStore.trackEvent('click_selection')
              }}
              rounded="full"
              className="sm:flex-1"
            >
              <FormattedMessage id="Get subscription" />
            </LinkButton>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export const ChooseLanguageDialog = () => {
  const store = useAppModule().appStore
  const authStore = useAppModule().authStore
  const state = useSnapshot(store.state)
  return (
    <Dialog.Root open={!state.language}>
      <Dialog.Content className="max-w-[min(95%,400px)]" closable={false}>
        <div className="flex flex-col gap-16">
          <p>
            <FormattedMessage id="defaultInterfaceLang" />
          </p>
          <div className="flex flex-col gap-12 sm:flex-row">
            <Button
              bg="white"
              id="demo_ru_language"
              onClick={() => {
                store.setLanguage('ru')
                authStore.trackMetrika('demo_popup_rus')
              }}
              className="sm:flex-1"
              rounded="full"
            >
              <FormattedMessage id="betterRussian" />
            </Button>
            <Button
              bg="white"
              id="demo_en_language"
              onClick={() => {
                store.setLanguage('en')
                authStore.trackMetrika('demo_popup_keep')
              }}
              rounded="full"
              className="sm:flex-1"
            >
              <FormattedMessage id="setThat" />
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}
